import {EnumTipoOcorrencia} from './../../../../shared/enums/EnumTipoOcorrencia';
import {MenuItem} from 'primeng/primeng';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';
import {VivaAdminGuard} from "../../guards/security/viva-admin.guard";

export class ItensMenuVivaVoz {

  constructor(private menuParent: MenuLateralComponent, private sessaoService: SessaoService) {
  }

  private hasPermission(roles: string[]): boolean {
    let isApresenta: boolean = false;
    let usuarioExterno = this.sessaoService.getUsuarioVivaSoli().usuarioExterno;
    roles.forEach(role => {
      if (this.sessaoService.getPerfilUsuarioSelecionadoVivaSoli().perfil === role
          || (role === 'VIVA_SOLI' && !usuarioExterno)) {
        isApresenta = true;
      }
    });
    return isApresenta;
  }

  public get itens(): MenuItem[] {
    return [
      // Viva Voz
      {
        label: "Viva Voz",
        icon: "fa fa-cogs",
        visible: this.hasPermission([
          PerfilModulo.VIVA_ADMIN.value,
          PerfilModulo.VIVA_SOLI.value
        ]),
        items: [
          {
            label: "Console Administrador",
            icon: "fa fa-angle-double-right",
            visible: this.hasPermission([
              PerfilModulo.VIVA_ADMIN.value
            ]),
            items: [
              {

                label: "Solicitações",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/console/administrador",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_ADMIN.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(1);
                }
              },
              {
                label: "Painel de Informações",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/console-administrador/painel-informacao-interna",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_ADMIN.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              {
                label: "Parametrização",
                icon: "fa fa-angle-double-right",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_ADMIN.value
                ]),
                items: [
                  {
                    label: "Origem",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/origem/",
                    visible: this.hasPermission([
                        PerfilModulo.VIVA_ADMIN.value
                    ]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Assunto",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/assunto/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Item",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/item/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Motivo",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/motivo/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Natureza",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/natureza/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Categorização",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/categorizacao-ocorrencia/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Avaliação",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/avaliacao-natureza/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Correio Eletrônico",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/mensagem-email/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: 'Justificativa',
                    icon: 'fa fa-angle-double-right',
                    routerLink: '/administracao/parametrizacao/justificativa/',
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: (event) => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Orientação Atendimento",
                    icon: "fa fa-angle-double-right",
                    routerLink:
                        "/administracao/parametrizacao/orientacao-atendimento/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Unidade Bloqueada",
                    icon: "fa fa-angle-double-right",
                    routerLink: "/administracao/parametrizacao/unidade-bloqueada/",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  },
                  {
                    label: "Ajuda",
                    icon: "fa  fa-question-circle",
                    routerLink: "/subsidiaria/ajuda",
                    visible: this.hasPermission([PerfilModulo.VIVA_ADMIN.value]),
                    command: event => {
                      this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                    }
                  }
                ]
              }
            ]
          },
          {
            label: "Console Unidade",
            icon: "fa fa-angle-double-right",
            visible: this.hasPermission([
              PerfilModulo.VIVA_SOLI.value,
              PerfilModulo.SOU_AUDITOR.value
            ]),
            items: [
              {
                label: "Tratar Ocorrência",
                icon: "fa fa-angle-double-right",
                routerLink: "/tratar-ocorrencia/tratar-ocorrencia-interna",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              {
                label: "Solicitações",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/console/unidade",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              {
                label: "Painel de Informações",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/console-unidade/painel-informacao-interna",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value,
                  PerfilModulo.SOU_AUDITOR.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              {
                label: "Tabela de Categorização",
                icon: "fa fa-angle-double-right",
                routerLink:
                    "/administracao/parametrizacao/tabela-categorizacao-ocorrencia/",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              // Item 50  planilha	-	Relatórios	Novo	Excluir a opção do menu, essa funcionalidade será feita futuramente.	c891344 > Console Unidade  > Relatórios	30/03/2021
              // {
              //   label: "Relatórios",
              //   icon: "fa fa-angle-double-right",
              //   visible: this.hasPermission([
              //     PerfilModulo.VIVA_ADMIN.value,
              //     PerfilModulo.VIVA_SOLI.value
              //   ]),
              //   command: event => {
              //     this.menuParent.itemMenuClick();
              //   }
              // }
            ]
          },
          {
            label: "Console Empregado",
            icon: "fa fa-angle-double-right",
            visible: this.hasPermission([
              PerfilModulo.VIVA_SOLI.value
            ]),
            items: [
              {
                label: "Registrar Ocorrência",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/ocorrencia-interna/novo",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              {
                label: "Minhas Ocorrências",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/minhas-ocorrencias",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              },
              {
                label: "Solicitações",
                icon: "fa fa-angle-double-right",
                routerLink: "/ocorrencia/console/empregado",
                visible: this.hasPermission([
                  PerfilModulo.VIVA_SOLI.value
                ]),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.INTERNA);
                }
              }
            ]
          }
        ]
      },
    ];
  }

}

