import { PerfilModulo } from '../../../../shared/models/perfil-modulo';
import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';

@Injectable()
export class VivaSoliGuard implements CanActivate {

    constructor(
        private sessaoService: SessaoService
    ) { }

    canActivate(): boolean {
        return this.sessaoService.getPerfilUsuarioSelecionado().perfil == PerfilModulo.VIVA_SOLI.value;
    }
}
