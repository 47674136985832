import {MenuItem} from 'primeng/primeng';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';

export class ItensMenuPadrao {

  constructor(private menuParent: MenuLateralComponent, private sessaoService: SessaoService) {
  }

  private hasPermission(roles: string[]): boolean {
    let isApresenta: boolean = false;
    let usuarioExterno = this.sessaoService.getUsuario().usuarioExterno;

    roles.forEach(role => {
      if (this.sessaoService.getPerfilUsuarioSelecionado().perfil === role
          || (role === 'SISOU_UNID_CAIXA' && !usuarioExterno)) {
        isApresenta = true;
      }
    });
    return isApresenta;
  }

  public get itens(): MenuItem[] {
    return [
      {
        label: "Página Inicial",
        icon: "fa fa-cogs",
        routerLink: "/subsidiaria",
        visible: this.hasPermission([
          //RTC16648077
          PerfilModulo.OUV_GESTORSISTEMA.value,
          //SAC
          PerfilModulo.SAC_AUDITORIA.value,
          PerfilModulo.SAC_GEST_ADM_SISTEMA.value,
          PerfilModulo.SAC_GEST_UNID_ATEND.value,
          PerfilModulo.SAC_OPER_UNID_ATEND.value,
          // VIVA VOZ
          PerfilModulo.VIVA_ADMIN.value,
          PerfilModulo.VIVA_SOLI.value,

          PerfilModulo.SISOU_UNID_CAIXA.value,
          PerfilModulo.SOU_TERCEIRIZADAGE.value,
          PerfilModulo.SOU_TERCEIRIZADAOP.value,
        ]),
        command: event => {
          this.menuParent.itemMenuClick(null);
        }
      }
    ];
  }

}

